import * as React from "react"

import {MDXRenderer} from "gatsby-plugin-mdx"
import { Link } from "gatsby"

import * as entities from "entities"

export interface IBlogPost {
  body: string
  fields: {
    slug: string
    authorSlug: string
    categorySlugs: {
      name: string
      slug: string
    }[]
    tagSlugs: {
      name: string
      slug: string
    }[]
  }
  frontmatter: {
    author: string
    title: string
    date: string
    prettyDate: string
  }
}

interface IProps {
  post: IBlogPost
}

/**
 * Displays a single blog post
 * @param props
 */

export default (props: IProps) => {
  // console.log("post props:", props)
  let post = props.post
  return (
    <article className="flex flex-col my-4 rounded-lg shadow-lg overflow-hidden">
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          {post.fields.categorySlugs.map((cat, idx) => {
            return (
              <span key={idx} className="text-sm leading-5 font-medium text-indigo-600 pr-2">
                <Link
                  to={"/category/" + cat.slug + "/"}
                  className="hover:underline"
                >
                  {cat.name}
                </Link>
              </span>
            )
          })}
          <Link to={post.fields.slug} className="block">
            <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
              {entities.decodeHTML5(post.frontmatter.title)}
            </h3>
          </Link>
          <div>
            {post.fields.tagSlugs.map((tag, idx) => {
              return (
                <Link
                  key={idx}
                  to={"/tag/" + tag.slug}
                  className="inline-flex items-center mx-1 px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-purple-100 text-purple-800"
                >
                  {entities.decodeHTML5(tag.name)}
                </Link>
              )
            })}
          </div>
          <div className="blog mt-3 text-base leading-6 text-gray-500 prose">
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            <Link to={"/author/" + post.fields.authorSlug + "/"}>
              <img
                className="h-10 w-10 rounded-full"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt={post.frontmatter.author}
              />
            </Link>
          </div>
          <div className="ml-3">
            <p className="text-sm leading-5 font-medium text-gray-900">
              <Link to={"/author/" + post.fields.authorSlug + "/"} className="hover:underline">
                {post.frontmatter.author}
              </Link>
            </p>
            <div className="flex text-sm leading-5 text-gray-500">
              <time dateTime={post.frontmatter.date}>
                {post.frontmatter.prettyDate}
              </time>
            </div>
          </div>
        </div>
      </div>
    </article>

  )
}