import * as React from "react"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"

import Header from "../site/Header"
import Footer from "../site/Footer"

import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
config.autoAddCss = false

const shortcodes = { Link }

/**
 * Standard page layout, used almost everywhere
 * @param children
 */

export default ({ children }) => (
  <div className="relative bg-gray-50 h-screen">
    <div className="flex flex-col h-full">
      <div className="flex-grow">
    <Header/>
    <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
      <MDXProvider components={shortcodes}>{children}</MDXProvider>
    </main>
      </div>
    <Footer/>
    </div>
  </div>
)