import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layouts/index-layout"
import { FluidObject } from "gatsby-image"
import { DateTime } from "luxon"

const entities = require("entities")
import Post, { IBlogPost } from "../components/blog/post"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons"

interface IData {
  allMdx: {
    edges: {
      node: IBlogPost
    }
  }
}

/**
 * Template for a page that displays a single blog post
 *
 * @param data
 * @param pageContext
 */

export default ({ data, pageContext }: { data: IData, pageContext: any }) => {
  // console.log("blog-post.tsx default")
  // console.log(data)
  // console.log(pageContext)

  // const cover =
  //   node.frontmatter.cover && node.frontmatter.cover.childImageSharp
  //     ? node.frontmatter.cover.childImageSharp.fluid
  //     : null
  // const cover = null
  // console.log("blog-post pageContext:", pageContext)
  // console.log("pageContext.previous:", pageContext.previous)
  // console.log("pageContext.previous.fields.slug", pageContext.previous.fields.slug)
  return (
    <Layout>
      <Post post={data.allMdx.edges[0].node} />
      <div className="mt-6 flex">
        {pageContext.previous && (
          <Link
            className="flex-none"
            to={pageContext.previous.fields.slug}
          >
            <FontAwesomeIcon icon={faArrowCircleLeft}/> {entities.decodeHTML5(pageContext.previous.frontmatter.title)}
          </Link>
        )}
        <span className="flex-auto"/>
        {pageContext.next && (
          <Link
            className="flex-none"
            to={pageContext.next.fields.slug}
          >
            {entities.decodeHTML5(pageContext.next.frontmatter.title)} <FontAwesomeIcon icon={faArrowCircleRight}/>
          </Link>
        )}
      </div>
    </Layout>
  )

}

export const query = graphql`
    query onePost($id: String!) {
        allMdx(filter: {id: {eq: $id}}) {
            edges {
                node {
                    fields {
                        slug
                        authorSlug
                        categorySlugs {
                            name
                            slug
                        }
                        tagSlugs {
                            name
                            slug
                        }
                    }
                    body
                    frontmatter {
                        author
                        title
                        date
                        prettyDate: date(formatString: "MMM Do, YYYY")
                    }
                }
            }
        }
    }
    
`
